import { environment } from '../environments/environment';

const assetsPath = 'assets';
export const PATHS = {
	assets: assetsPath,
	wall: 'wall',
	flags: 'flags',
	user: 'user',
	countries: 'countries',
	event: 'event',
	groups: 'groups',
	settings: 'settings',
	account: 'account',
	notifications: 'notifications',
	store: 'store',
	friends: 'friends',
	ranking: 'ranking',
	recover: 'recover',
	verify: 'verify',
	register: 'register',
	images: `${assetsPath}/images`,
	avatars: environment.avatarPath,
	eventPics: environment.eventPicPath
};

export enum SETTINGS_SUBSECTION {
	all = 'all',
	account = 'account',
	notifications = 'notifications'
}

export const GENERAL = {
	app: {
		title: `Deflaggr${!environment.production ? ' (dev)' : ''}`
	},
	handlers: {
		wall: {
			name: 'Wall',
			path: `/${PATHS.wall}`,
			description: ''
		},
		user: {
			name: 'User',
			nameEdit: 'Account details',
			flags: { path: PATHS.flags },
			basePath: `/${PATHS.user}`
		},
		friends: {
			name: 'Friends',
			path: `/${PATHS.friends}`,
			ranking: {
				name: 'Friends Ranking',
				path: `/${PATHS.friends}/${PATHS.ranking}`
			}
		},
		countries: {
			name: 'Countries list',
			path: PATHS.countries,
			single: {
				name: 'Country',
				users: {
					path: 'users'
				}
			}
		},
		event: {
			name: 'Flag Event',
			path: PATHS.event,
		},
		groups: {
			name: 'My Groups',
			nameSingle: 'Group',
			path: PATHS.groups,
			flags: {
				name: 'Flags',
				path: PATHS.flags
			},
			members: {
				name: 'Members',
				path: 'members'
			},
			ranking: {
				name: 'Group Ranking',
				path: PATHS.ranking
			}
		},
		settings: {
			name: 'Settings',
			path: `/${PATHS.settings}`,
			[SETTINGS_SUBSECTION.account]: {
				name: 'Account',
				path: `/${PATHS.settings}/${PATHS.account}`
			},
			[SETTINGS_SUBSECTION.notifications]: {
				name: 'Notifications',
				path: `/${PATHS.settings}/${PATHS.notifications}`
			}
		},
		recover: {
			name: 'Recover',
			path: `/${PATHS.recover}`
		},
		verify: {
			name: 'Verification',
			path: `/${PATHS.verify}`
		},
		register: {
			name: 'Sign up',
			path: `/${PATHS.register}`
		}
	}
};

export const ASSETS = {
	flags: {
		path: `/${PATHS.images}/flags/`,
		ext: '.svg'
	}
};

export const KEYS = {
	geoCharts: 'AIzaSyAfl3GpGKueBM-GV4gJMUk3LRRlfdAX_Mo' // Google Maps+GeoCoding API key
};

export const FLAGEVENTS = {
	// eslint-disable-next-line no-useless-escape
	dateFromBack: /^(\d{4})\-(\d{2})\-(\d{2})\s(\d{2}):(\d{2}):(\d{2})$/,
	dateFromEventUrl: /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/,
	howManyEachTime: 50,
	verbs: {
		VERBGETFIRSTTIME: 'got',
		VERBGETRESTTIMES: 'renewed'
	},
	hotnessColorMax: 10
};

export const FORM_CONFIG = {
	registration: {
		email: { max: 45 },
		username: { max: 45, min: 6 },
		password: { min: 6 },
		name: { max: 45 },
		surname: { max: 45 },
		bio: { max: 250 }
	}
};

export enum Stores { android = 'android', ios = 'ios' }
export const APP: { [key in Stores]: { name: string; store: string; } } = {
	android: {
		name: 'Google Play',
		store: 'https://play.google.com/store/apps/details?id=com.deflaggr.mobile'
	},
	ios: {
		name: 'Apple App Store',
		store: 'https://apps.apple.com/app/deflaggr/id1537432579'
	}
};

export enum STATIC_SECTION {
	about = 'about',
	content = 'content',
	help = 'help',
	terms = 'terms',
	privacy = 'privacy'
}

export enum EVENTSWITHFRIENDS {
	YES = -1,
	NO = 0,
	ONLY = 1
}

export enum FRIENDSTATUS {
	NOTFRIEND = 0,
	REQUESTED = 1,
	FRIEND = 2,
	BLOCKED = 3
}

export enum FRIENDACTION {
	REMOVEFRIEND = 0,
	ADDFRIEND = 2,
	BLOCKUSER = 3
}
