// Environment for remote dev server (npm run start)

const server = 'https://apidev.deflaggr.com/';
const imgServer = 'https://userimg.deflaggr.com/';

export const environment = {
	production: false,
	API: {
		endpoint: `${server}v3/`
	},
	avatarPath: `${imgServer}avatars/`,
	eventPicPath: `${imgServer}eventpics/`
};

